<template>
	<div>
		<v-hover v-slot:default="{ hover }">
			<v-avatar size="160">
				<v-img :src="user.imgURL" />
				<v-fade-transition>
					<v-overlay v-if="hover" absolute color="#036358">
						<v-btn small color="primary" @click="switchEditMode()"> <v-icon left>mdi-camera</v-icon> {{ $t('settings.update') }} </v-btn>
					</v-overlay>
				</v-fade-transition>
			</v-avatar>
		</v-hover>
		<v-dialog v-model="editMode" persistent max-width="600px">
			<v-card rounded="xl">
				<v-card-title>
					<span class="headline">{{ $t('profile.profilePicture') }}</span>
				</v-card-title>
				<v-card-text>
					<v-form ref="form" v-model="valid">
						<v-file-input v-model="picture" :label="$t('profile.profilePicture')" @change="onPictureUpload" />
					</v-form>

					<div v-if="pictureCompressed && pictureUrl" class="text-center">
						<h3>{{ $t('explorer.readyToUpload') }}</h3>
						<p>{{ picture.name }}</p>
						<v-avatar size="160">
							<v-img :src="pictureUrl" />
						</v-avatar>
					</div>
				</v-card-text>
				<v-card-actions>
					<v-btn color="error" text @click="callDelete()">{{ $t('settings.deletePicture') }}</v-btn>
					<v-spacer />
					<v-btn color="blue darken-1" text @click="switchEditMode()">{{ $t('search.close') }}</v-btn>
					<v-btn color="blue darken-1" text @click="callUpdate()">{{ $t('settings.update') }}</v-btn>
				</v-card-actions>
			</v-card>
		</v-dialog>
	</div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import Compressor from 'compressorjs'

export default {
	name: 'UserPicture',
	props: {
		loadingParent: {
			type: Boolean,
			required: true
		}
	},
	data() {
		return {
			loading: false,
			editMode: false,
			valid: false,
			picture: null,
			pictureCompressed: null,
			pictureUrl: null
		}
	},
	computed: {
		...mapGetters({
			user: 'users/user'
		})
	},
	methods: {
		switchEditMode() {
			this.editMode = !this.editMode
		},
		onPictureUpload() {
			new Promise((resolve, reject) => {
				new Compressor(this.picture, {
					quality: 0.9,
					success: resolve,
					error: reject
				})
			})
				.then((result) => {
					this.pictureCompressed = result
					this.pictureUrl = URL.createObjectURL(this.pictureCompressed)
				})
				.catch((error) => {
					console.log(error)
				})
		},
		callUpdate() {
			this.$refs.form.validate()
			if (!this.valid) return
			this.loading = true
			const formData = new FormData()
			formData.append('profilePicture', this.pictureCompressed, this.pictureCompressed.name)
			this.updateUserPicture({ userID: this.user.id, formData }).then(() => {
				this.switchEditMode()
				this.picture = null
				this.pictureCompressed = null
				this.pictureUrl = null
				this.loading = false
			})
		},
		callDelete() {
			this.$refs.form.validate()
			if (!this.valid) return
			this.loading = true
			this.deleteUserPicture(this.user.id).then(() => {
				this.switchEditMode()
				this.loading = false
			})
		},
		...mapActions('users', ['updateUserPicture', 'deleteUserPicture'])
	}
}
</script>
